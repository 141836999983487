<template>
  <v-form @submit.prevent="submitResponse">
    <h2 v-if="task.content.config.heading" class="mb4">
      <stemble-latex :content="task.content.config.heading" style="white-space: pre-wrap" />
    </h2>
    <div v-for="uiContextItem in task.content.config.uiContext" :key="uiContextItem.uuid">
      <div v-if="uiContextItem.contextType === 'text_block'">
        <text-block-ui-component :text="uiContextItem.text" />
      </div>
      <div v-else-if="uiContextItem.contextType === 'multiple_choice'">
        <v-radio-group
          v-model="inputs[uiContextItem.uuid]"
          :label="task.content.config.text"
          class="pl-3"
        >
          <template #label>
            <stemble-latex :content="uiContextItem.text" style="white-space: pre-wrap" />
          </template>
          <v-radio
            v-for="option in uiContextItem.multipleChoiceOptions"
            :key="option.key"
            :value="option.key"
          >
            <template #label>
              <stemble-latex :content="option.label" />
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div v-else-if="uiContextItem.contextType === 'randomized_passage'">
        <stemble-latex
          :content="uiContextItem.randomTextPassages[examplePostNumber.value]"
          style="white-space: pre-wrap"
        />
      </div>
      <div v-else-if="uiContextItem.contextType === 'dynamic_table'">
        <dynamic-table-input
          v-model="inputs[uiContextItem.uuid]"
          class="mb-4"
          :is-marking="isMarking"
          :enable-editable-headers="uiContextItem.allowEditableHeaders"
          :enable-dynamic-columns="uiContextItem.allowDynamicColumns"
        ></dynamic-table-input>
      </div>
      <div v-else-if="uiContextItem.contextType === 'student_response'">
        <s-textarea
          v-model="inputs[uiContextItem.uuid]"
          outlined
          :counter="uiContextItem.characterLimit > 0"
          :maxlength="uiContextItem.characterLimit > 0 ? uiContextItem.characterLimit : undefined"
        />
      </div>
      <div v-else-if="uiContextItem.contextType === 'file_upload'">
        <p><b>Max file size</b>: 50 MB</p>
        <p><b>Accepted file types</b>: {{ formatFileExtensions(uiContextItem.text) }}</p>
        <div class="mb-3">
          <v-file-input
            v-model="attachments"
            multiple
            class="mt-0 pt-0"
            v-bind="uiContextItem.text && {accept: uiContextItem.text}"
          />
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import DynamicTableInput from '@/tasks/components/inputs/DynamicTableInput.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import TextBlockUiComponent from '@/tasks/components/task-ui-components/TextBlockUiComponent.vue';
import {TableComponentName} from '@/tasks/types/DynamicTable';

export default {
  name: 'AiSelectableContextQuestion',
  components: {DynamicTableInput, TextBlockUiComponent, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const inputs = {};
    this.task.content.config.uiContext.forEach((uiContextItem) => {
      if (
        uiContextItem.contextType === 'student_response' ||
        uiContextItem.contextType === 'multiple_choice'
      ) {
        inputs[uiContextItem.uuid] = '';
      } else if (uiContextItem.contextType === 'dynamic_table') {
        const headers = uiContextItem.text.split(',').map((title, index) => ({
          label: title.trim(),
          component: TableComponentName.STextarea,
          prop: (index + 1).toString(),
        }));

        if (headers.length === 0) {
          headers.push({
            label: '',
            component: TableComponentName.STextarea,
            prop: '1',
          });
        }

        inputs[uiContextItem.uuid] = {
          headers: headers,
          rows: [],
        };
      }
    });
    return {
      inputs,
      attachments: [],
    };
  },
  computed: {
    examplePostNumber() {
      return this.taskState.getValueBySymbol('randomized_passage').content;
    },
  },
  methods: {
    formatFileExtensions(fileExtensions) {
      let returnedString = '';
      if (!fileExtensions) {
        return returnedString;
      }
      returnedString += 'Accepted file types: ';
      return (
        returnedString +
        fileExtensions
          .split(',')
          .map((ext) => ext.trim().replace(/\./g, '').toUpperCase())
          .join(', ')
      );
    },
  },
};
</script>
